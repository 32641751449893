import { Container, Grid, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const Menu = () => {
  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/funded-by-eu-white-bg.png?alt=media&token=d2deb92c-b499-49f2-97b4-b1d4a6d01694"
            style={{ width: "100px" }}
            alt="logo"
          />
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2}>
            <Link
              style={{
                textDecoration: "none",
                color: "#000000",
                cursor: "pointer",
              }}
              href="/"
            >
              <Typography>Home</Typography>
            </Link>
            <br />
            <Link
              style={{
                textDecoration: "none",
                color: "#000000",
                cursor: "pointer",
              }}
              href="/about"
            >
              <Typography>About</Typography>
            </Link>
            <br />
            <Link
              style={{
                textDecoration: "none",
                color: "#000000",
                cursor: "pointer",
              }}
              href="/team"
            >
              <Typography>Team</Typography>
            </Link>
            <br />
            <Link
              style={{
                textDecoration: "none",
                color: "#000000",
                cursor: "pointer",
              }}
              href="/contact"
            >
              <Typography>Contact</Typography>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Menu;
