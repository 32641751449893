import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

const Footer = () => {
  return (
    <Box style={{ color: "rgba(255, 255, 255, 0.47)" }}>
      <Box
        style={{
          backgroundColor: "#303030",
          paddingTop: 60,
          paddingBottom: 60,
        }}
      >
        <Container>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">Useful Links</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Stack>
                        <Link
                          href="/"
                          style={{
                            color: "white",
                            textDecoration: "underline",
                            opacity: 0.6,
                            cursor: "pointer",
                          }}
                        >
                          <Typography variant="body1">Home</Typography>
                        </Link>
                        <Link
                          href="/about"
                          style={{
                            color: "white",
                            textDecoration: "underline",
                            opacity: 0.6,
                            cursor: "pointer",
                          }}
                        >
                          <Typography variant="body1">About</Typography>
                        </Link>
                        <Link
                          href="/team"
                          style={{
                            color: "white",
                            textDecoration: "underline",
                            opacity: 0.6,
                            cursor: "pointer",
                          }}
                        >
                          <Typography variant="body1">Team</Typography>
                        </Link>
                        <Link
                          href="/contact"
                          style={{
                            color: "white",
                            textDecoration: "underline",
                            opacity: 0.6,
                            cursor: "pointer",
                          }}
                        >
                          <Typography variant="body1">Contact</Typography>
                        </Link>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">Address</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Georges Tabet St.</Typography>
                  <Typography>Beirut, Lebanon</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">Contact</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>+961 1 215 735</Typography>
                  <Typography>eu.lb.taf@gmail.com</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        style={{
          backgroundColor: "#252525",
          paddingTop: 60,
          paddingBottom: 60,
        }}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/funded-by-eu-white-bg.png?alt=media&token=d2deb92c-b499-49f2-97b4-b1d4a6d01694"
                style={{ width: "100px", backgroundColor: "white" }}
                alt="logo"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                This project is funded by the European Union
              </Typography>
              <Typography>
                Grant Reference: EuropeAid/140189/DH/SER/LB || ENI/2019/413-130
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={6}>
                  <Typography>
                    This website was created and maintained with the financial
                    support of the European Union. Its contents are the sole
                    responsibility of DAI and do not necessarily reflect the
                    views of the European Union.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>Cpoyright © 2020</Typography>
                  <Typography>All rights reserved.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
