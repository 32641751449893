import { Box, Container, Grid, Link, Slider, Typography } from "@mui/material";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Menu from "../components/Menu";

const About = () => {
  return (
    <>
      <Header />
      <Menu />
      <Box
        style={{
          background:
            "url(https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/dark-header-bg.png?alt=media&token=f4b831e3-b66a-4993-8abc-8a8a43251264) center center no-repeat",
        }}
        pt={5}
        pb={5}
        mb={5}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" color="white">
                Innovate, Diversify & Build Competitiveness
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="white">
                Technical Assistance Facility for the Lebanese Private Sector
                “Fostering Growth and Job Creation”
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}></Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography color="primary" variant="h5">
              Introduction
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="justify">
              <b>The Technical Assistance Facility (EU-TAF)</b> for the Lebanese
              Private Sector is a 34-month support initiative funded by{" "}
              <Link
                href="https://south.euneighbours.eu/country/lebanon/"
                target="_blank"
              >
                the European Union (EU)
              </Link>{" "}
              to <b>foster economic growth and job creation in Lebanon</b>.
            </Typography>
            <br />
            <Typography>
              The EU-TAF project is implemented by DAI in partnership with SPEED
              Lebanon SAL, Sequa gGmbH and The Technology Centre of the Czech
              Academy of Sciences. The contracting authority is the European
              Union Delegation to Lebanon.
            </Typography>
            <br />
            <Typography>
              The project contributes to the stabilisation and resilience of the
              Lebanese Economy by directly supporting the productive sector of
              the Lebanese industry through improving innovation, technology and
              quality, promoting modernisation and diversification, building
              competitiveness and market share, locally and internationally
              realising the country’s export potential.
            </Typography>
            <br />
            <Typography>
              The project has identified three specific sectors with
              high-potential to achieve the significant development required for
              Lebanon’s recovery with their available resources:
              <ul>
                <li>The Agri-food sector</li>
                <li>The Healthcare sector</li>
                <li>The ICT/Creative sector</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={10}>
                <Slider
                  value={[0, 100]}
                  valueLabelDisplay="off"
                  disableSwap
                  marks={[
                    { value: 0, label: "Start: February 2020" },
                    { value: 100, label: "End: 24 December 2022" },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary" variant="h5">
              Program Primary Areas
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="justify">
              There are two primary areas to the EU-TAF support *, in which the
              project supports enterprises and their management teams realize
              commercial potential.{" "}
              <i>
                A company can be eligible for both below-mentioned support
                programmes.
              </i>
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Box style={{ border: "1px solid #009966", minHeight: "100%" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box style={{ backgroundColor: "#009966" }} p={2}>
                    <Typography style={{ color: "white" }} variant="h6">
                      Innovation Research & Development
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box p={2} pt={0}>
                    <Typography textAlign="justify">
                      Innovation is fundamental to the competitiveness of
                      companies and can be achieved in all areas of their value
                      chain and in the value systems of industry sectors.{" "}
                      <b>
                        The project supports enterprises to innovate and to
                        access technology transfer opportunities, both locally
                        and internationally.
                      </b>{" "}
                      Through access to appropriate expertise, global innovation
                      networks and partners, the project aims to boost the
                      Lebanese innovation system and industrial competitiveness
                      in targeted sectors with potential to gain and deploy real
                      international competitive advantage through import
                      substitution and export increase.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box style={{ border: "1px solid #009966" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box style={{ backgroundColor: "#009966" }} p={2}>
                    <Typography style={{ color: "white" }} variant="h6">
                      Trade & Market Access
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box p={2} pt={0}>
                    <Typography textAlign="justify">
                      <b>
                        The EU Lebanon Association Agreement has established
                        preferential arrangements for trade, to boost and
                        increase Lebanese exports to the EU.
                      </b>{" "}
                      While this has seen a doubling of exports, it is from a
                      low base and the balance of trade has grown significantly
                      in the EU’s favour.
                    </Typography>
                    <Typography color="primary" textAlign="left">
                      <b>
                        <i>
                          In 2021, the EU imported $523 million worth of goods
                          from Lebanon and exported $4,148 million worth of
                          goods, resulting in a negative balance of trade for
                          Lebanon of $-3,625 million
                        </i>
                      </b>
                    </Typography>
                    <Typography>
                      Going forward, it is important that efforts in support of
                      overcoming the issues and impediments are deployed to
                      support exporting and internationalising companies. The
                      project engages directly with relevant sectoral
                      enterprises to ensure compliance with international
                      standards including sanitary and phytosanitary, build
                      internationalisation capability and facilitate market
                      access networks, channels and partners to increase
                      exports.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="justify">
              * It is possible that an enterprise will get support to both
              innovate and internationalise
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary" variant="h5">
              Project Context
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="justify">
              While Lebanon is an innovative and creative economy, particularly
              in the ICT and agri-food fields, many of the productive sectors
              suffer from the lack of an enabling environment translating into
              under investment, poor productivity, minimal business development
              support and poor access to appropriate forms of finance.
              Businesses need to leverage their know-how and access to
              information on international quality standards, market knowledge,
              and appropriate networks and local and international channels to
              increase their performance and competitiveness in international
              markets and create high-quality import substitution to respond to
              the local demand.
            </Typography>
            <br />
            <Typography textAlign="justify">
              Our TA Facility for the Lebanese Private Sector aims to overcome
              many of the challenges and impediments that such businesses
              encounter in deploying innovation, improving competitiveness, and
              penetrating, more deeply local and international markets.
            </Typography>
            <Typography textAlign="justify">
              The initiative and associated support activities have become even
              more relevant in the context of the economic, financial and social
              upheaval with the Syrian refugee crisis, the devaluation of the
              Lebanese currency, the Beirut Port explosion and the likely
              impacts of the Covid- 19 pandemic on the potential and recovery of
              the Lebanese economy.
            </Typography>
            <br />
            <Typography textAlign="justify">
              Our approach is cognizant, always, of the current and evolving
              situation and adapts appropriately to the challenges and needs of
              our supported enterprises.
            </Typography>
            <Typography textAlign="justify">
              We are adopting an interactive and transparent method building on
              the knowledge capacity of the local ecosystem to design, develop
              and implement appropriate initiatives and support services to
              ensure economic growth and job creation potential in the private
              sector in Lebanon is enhanced and future proofed
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary" variant="h5">
              Project Status
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="justify">
              The project ended in December 2022 with a closing ceremony that
              was attended by the 21 companies.
            </Typography>
            <br />
            <Typography textAlign="justify">
              The business leaders of the participating enterprises are now
              equipped with the specific tools and know-how to scale their
              operations to build competitiveness, realise innovation
              (develop/acquire new products/processes) and win more profitable
              market share.
            </Typography>
            <Typography textAlign="justify">
              Each assisted enterprise has completed a development
              roadmap/action plan ensuring the successful implementation of
              their respective growth plan
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary">
              Next Steps
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="justify">
              The EU-TAF project developed the knowledge hub as the programme's
              legacy, to continue supporting the Lebanese productive sectors.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="justify">
              The knowledge hub is a dynamic and multipurpose platform that
              helps connect businesses with the right resources, whether they
              are looking for financial or non-financial support, or innovative
              ideas and products. The platform supports SMEs in finding the
              right opportunities and supports innovation organizations in
              promoting their opportunities. The enabled-user-generated content
              can be used to build networks of peers and enhance the profiling
              of stakeholders to enable partnerships and better access to
              resources. To make different support organizations and local
              initiatives more visible, they are integrated into a directory
              that provides intuitive mappings and category-based
              visualizations. Each user has a unique dashboard that
              automatically matches users’ interests to opportunities, key
              services, and organizations of mutual interest among other
              relevant resources.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link to="#" variant="button">
              Discover the Knowledge Hub (Coming Soon!)
            </Link>
          </Grid>
        </Grid>
        <Box mt={12} />
      </Container>
      <Footer />
    </>
  );
};

export default About;
