import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Menu from "../components/Menu";

import WorkIcon from "@mui/icons-material/Work";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

const Contact = () => {
  return (
    <>
      <Header />
      <Menu />
      <Box
        style={{
          background:
            "url(https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/dark-header-bg.png?alt=media&token=f4b831e3-b66a-4993-8abc-8a8a43251264) center center no-repeat",
        }}
        pt={5}
        pb={11}
        mb={5}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" color="white">
                Get in touch
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Card>
              <CardContent>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <WorkIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Address"
                      secondary="George Tabet Street, Beirut, Lebanon"
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <LocalPhoneIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Landline" secondary="01 215 735" />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <EmailIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Email"
                      secondary="declan.gordon.carroll@lbdev.eu"
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <EmailIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Email"
                      secondary="amr.radwan@lbdev.eu"
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card>
              <CardMedia
                component="iframe"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.1523008184467!2d35.516151816344575!3d33.88573123393157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f171dcce445a5%3A0x25f8f790486b1fc3!2sDAI!5e0!3m2!1sen!2ses!4v1660640500401!5m2!1sen!2ses"
                style={{ border: 0, minHeight: 345 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Box mt={10} />
      <Footer />
    </>
  );
};

export default Contact;
