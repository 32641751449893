import React from "react";
import { Container, Grid, Typography } from "@mui/material";

const Header = () => {
  return (
    <div
      style={{
        backgroundColor: "#455560",
        paddingTop: 5,
        paddingBottom: 5,
        color: "white",
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="caption">
              Technical Assistance Facility for the Lebanese Private Sector
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Header;
