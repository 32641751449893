import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Menu from "../components/Menu";

const Team = () => {
  const teamMembers = [
    {
      name: "Declan Gordon Carroll",
      position: "Team Leader",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FDeclan.jpg?alt=media&token=9b2ed335-210c-4492-a943-15e4558d9b4c",
    },
    {
      name: "Ghada Armali",
      position: "Business and PSD",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FGhada%20Armali.jpg?alt=media&token=e0c0ae1c-5e78-446e-b378-142e9f31e0cd",
    },
    {
      name: "Yusuf Tokdemir",
      position: "Ops and Innovation Lead in Agri-food sector",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FYusufTokdemir_Photo_Dec2022.jpg?alt=media&token=f7b9ed66-267a-4cbf-a893-83d2ea5aef05",
    },
    {
      name: "Abel Rodriguez",
      position: "Agri-food GM Advisory Lead",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FAbel.png?alt=media&token=19f6e1fd-7677-43a5-bacd-ba1656d1559b",
    },
    {
      name: "Ammar Alshami",
      position: "Agri-food BD & Internationalisation Lead",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FAMMAR_PIC_PERSONAL_PASS.jpg?alt=media&token=983a6c14-50dc-4f76-ab97-ca4897caaf85",
    },
    {
      name: "Maurice Mouawad",
      position: "ICT GM Advisory Lead",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FMaurice%20Moawad.jpg?alt=media&token=14a752b7-b569-4a43-aa07-15c91ae88dd9",
    },
    {
      name: "Khalil Rassy",
      position: "ICT Ops& Innovation Lead",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2Fkhalil.rassy.jpg?alt=media&token=6ac22ddc-f1bc-4e67-8d71-46a677258d4a",
    },
    {
      name: "Sarah Ghamineh",
      position: "ICT BD & Internationalisation Lead",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FSarah%20Ghanimeh%20Portrait.jpg?alt=media&token=7eaca4a4-d84c-4fa5-955a-042e21965d78",
    },
    { name: "Manel Chikh", position: "Healtcare GM Advisory Lead", photo: "" },
    {
      name: "Brona O'Neill",
      position: "Healtcare Ops& Innovation Lead",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FBrona.jpg?alt=media&token=db9095ca-c2af-4eda-a5a6-9c0739604420",
    },
    {
      name: "Margarita Gomez",
      position: "Marketing Advisor",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FMargarita%20Gonzalez_3.jpg?alt=media&token=7239667f-3940-48a4-a138-6c58ccee8256",
    },
    {
      name: "Mariem Ben Mallem",
      position: "BD&start-up",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FMariem.jpg?alt=media&token=1e28b6b1-e167-46a8-84ae-9df92e7d7136",
    },
    { name: "Antonio Texeira", position: "IPR", photo: "" },
    {
      name: "Sami El Khatib",
      position: "BD Support",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FSami%20El%20Khatib.jpg?alt=media&token=cf1d6e37-1332-43c0-bb17-24974d13cacb",
    },
    {
      name: "Vanessa Brassitos",
      position: "BD Support",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/team-taf%2FVanessa.jpeg?alt=media&token=edafe954-4862-48f6-923a-7506c633961d",
    },
  ];

  return (
    <>
      <Header />
      <Menu />
      <Box
        style={{
          background:
            "url(https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/dark-header-bg.png?alt=media&token=f4b831e3-b66a-4993-8abc-8a8a43251264) center center no-repeat",
        }}
        pt={5}
        pb={5}
        mb={5}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" color="white">
                Meet the team
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Meet the EU-TAF sector specific expert team of international and
              local consultants supporting the overall development and
              implementation of the programme to help companies accelerate
              innovation development and acquire market shares domestically and
              internationally.
            </Typography>
          </Grid>
        </Grid>
        <Box mt={10} />
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {teamMembers.map((item) => (
              <Grid item md={6} xs={12} key={item.name}>
                <Grid container spacing={3}>
                  <Grid item md={5}>
                    <Card>
                      <CardMedia
                        image={item.photo}
                        style={{ paddingTop: "150%" }}
                      />
                    </Card>
                  </Grid>
                  <Grid item md={7}>
                    <Typography variant="h6">{item.name}</Typography>
                    <Typography>{item.position}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Box mt={10} />
      </Container>
      <Footer />
    </>
  );
};

export default Team;
