import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Menu from "../components/Menu";

const Home = () => {
  return (
    <>
      <Header />
      <Menu />
      <Box
        style={{
          background:
            "url(https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/light-home-bg.png?alt=media&token=7a4dda70-253f-4d73-910e-a1ca9b9bb156)",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <Container style={{ paddingTop: 100, paddingBottom: 100 }}>
          <Grid container spacing={3} direction="column" alignItems="center">
            <Grid item>
              <Typography
                color="primary"
                variant="h1"
                style={{ fontWeight: "bold" }}
              >
                Scale your business
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">
                Rebuilding Lebanon’s Economy Together
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Button component={Link} variant="outlined" href="/about">
                    Learn More
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#009966" }}>
        <Container
          style={{
            marginTop: 20,
            marginBottom: 20,
            paddingBottom: 20,
            color: "white",
          }}
        >
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h5">Applications closed!</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        style={{
          marginTop: 50,
          marginBottom: 50,
        }}
        pt={8}
        pb={10}
      >
        <Container>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={8} xs={12}>
              <Typography style={{ textAlign: "center" }}>
                The European Union launched, in February 2020, a new support
                initiative to work with innovative and creative, growth oriented
                Lebanese enterprises in the sectors of:
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    width={100}
                    src="https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/agrifood.png?alt=media&token=0622be91-e2b4-46d9-92c0-628add3d26d5"
                    alt="agriculture food"
                  />
                  <Typography>Agri-food</Typography>
                </div>
                <div style={{ textAlign: "center" }}>
                  <img
                    width={100}
                    src="https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/innovation.png?alt=media&token=e5289839-fd87-4a45-849a-0795c266de75"
                    alt="creative ICT"
                  />
                  <Typography>Creative/ICT</Typography>
                </div>
                <div style={{ textAlign: "center" }}>
                  <img
                    width={100}
                    src="https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/healthcare.png?alt=media&token=4ff2b87e-1d3d-4b81-a16b-cb3f90ecbc03"
                    alt="healthcare"
                  />
                  <Typography>Healthcare</Typography>
                </div>
              </Stack>
            </Grid>
            <Grid item md={8} x={12}>
              <Typography style={{ textAlign: "center" }}>
                The Technical Assistance for Lebanese Private Sector (EU-TAF)
                Project aimed at supporting businesses to increase their market
                shares locally and internationally through hands-on expertise.
                This 34-month support initiative works towards supporting the
                participating enterprises develop and explore new innovative
                ideas, improve their companies’ competitiveness, capture more
                profitable market shares, both locally and internationally, and
                internationalize their operations.
              </Typography>
            </Grid>
            <Grid item md={8} x={12}>
              <Typography style={{ textAlign: "center" }}>
                SDGs targeted by the programme:
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <img
                  style={{ width: 150 }}
                  src="https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/Sustainable_Development_Goal_8.png?alt=media&token=42268eef-67d6-46c6-ba3d-868d7917703b"
                  alt="sdg-8"
                />
                <img
                  style={{ width: 150 }}
                  src="https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/Sustainable_Development_Goal_9.png?alt=media&token=33b9a26b-c61c-4cb8-8750-4871554d79e5"
                  alt="sdg-9"
                />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#009966" }}>
        <Container
          style={{
            marginTop: 20,
            paddingBottom: 20,
            color: "white",
          }}
        >
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h4">Building GLOBAL Enterprises</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        style={{
          backgroundColor: "#f3f3f3",
        }}
        pt={8}
        pb={10}
      >
        <Container>
          <Grid
            container
            spacing={3}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={8} xs={12}>
              <Typography align="center">
                Partnering with dynamic, growth-oriented companies, the
                sector-specific expert teams are working intensively towards{" "}
                <b>
                  supporting business leaders achieve their business development
                  objectives
                </b>
                .<br />
                This includes access to international and local consulting
                expertise, exposure to business networks and channel partners,
                technology transfer opportunities, access to supply chains and
                financing methods.
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Typography align="center">
                The teams are strategically helping the selected companies in
                completing, reviewing and strengthening existing development
                plans or assisting in developing new ones, to build
                competitiveness, realize innovation (develop/acquire new
                products/processes) and win more profitable market share.
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Typography align="center">
                Such support is addressing the main barriers and challenges that
                are impeding development and could, for example, work to
                improve, operational processes, quality and technology,
                marketing and sales, branding, organize soft landings in
                international markets or to introduce new sales or supply
                channels, partners or customers.
                <br />
                The assistance is customized to answer the needs of each
                business to successfully support, and complement their growth
                and expansion plans, together with management.
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Card>
                <CardMedia
                  style={{ minHeight: 350 }}
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  component="iframe"
                  src="https://www.youtube.com/embed/ptQJ1DuVrrs"
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#009966" }}>
        <Container
          style={{
            marginTop: 20,
            paddingBottom: 20,
            color: "white",
          }}
        >
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h4">Cohorts</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={8} pb={10}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={4} xs={12}>
              <Typography textAlign="left" variant="h6">
                <i>Cohort 1</i>
              </Typography>
              <Typography color="primary" variant="h3">
                11 Enterprises
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid container spacing={10}>
                <Grid item md={4} xs={12}>
                  <Typography textAlign="center" variant="h3" color="primary">
                    5
                  </Typography>
                  <Typography textAlign="center">in Agri-food</Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography textAlign="center" variant="h3" color="primary">
                    4
                  </Typography>
                  <Typography textAlign="center">in creative/ICT</Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography textAlign="center" variant="h3" color="primary">
                    2
                  </Typography>
                  <Typography textAlign="center">in Healthcare</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography textAlign="center" variant="h3" color="primary">
                    8
                  </Typography>
                  <Typography textAlign="center">
                    supported in Innovation R&D
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography textAlign="center" variant="h3" color="primary">
                    11
                  </Typography>
                  <Typography textAlign="center">
                    Supported in Trade & Market Access
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mt={10} mb={10}>
                <hr />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography textAlign="left" variant="h6">
                <i>Cohort 2</i>
              </Typography>
              <Typography color="primary" variant="h3">
                10 Enterprises
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid container spacing={10}>
                <Grid item md={6} xs={12}>
                  <Typography textAlign="center" variant="h3" color="primary">
                    8
                  </Typography>
                  <Typography textAlign="center">in Agri-food</Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography textAlign="center" variant="h3" color="primary">
                    10
                  </Typography>
                  <Typography textAlign="center">in creative/ICT</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography textAlign="center" variant="h3" color="primary">
                    10
                  </Typography>
                  <Typography textAlign="center">
                    supported in Innovation R&D
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography textAlign="center" variant="h3" color="primary">
                    10
                  </Typography>
                  <Typography textAlign="center">
                    Supported in Trade & Market Access
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box style={{ backgroundColor: "#009966" }}>
        <Container
          style={{
            marginTop: 20,
            marginBottom: 20,
            paddingBottom: 20,
            color: "white",
          }}
        >
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h4">Catalogue</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        style={{
          backgroundColor: "#f3f3f3",
        }}
      >
        <Container>
          <Grid container spacing={3} style={{ paddingTop: 10 }}>
            <Grid item xs={12}>
              <Typography>
                For more information on the Lebanese businesses and the support
                they received through the EU-TAF programme:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardMedia
                  title="Turning challenges into opportunities"
                  style={{ minHeight: 500 }}
                  component="iframe"
                  src="https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/FINAL_DIGITAL_Turning_challenges_into_opportunities.pdf?alt=media&token=d2222ef4-3e83-4908-b0d4-86028a4b6519"
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        pt={10}
        pb={10}
        style={{
          backgroundColor: "#f3f3f3",
        }}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/eu-taf.appspot.com/o/infograph.png?alt=media&token=cc5c2bcb-6289-4a29-ad81-3d4d0f10687c"
                  style={{ width: "100%" }}
                  alt="road map"
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Home;
